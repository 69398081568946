import React from 'react';

import './style.css';

interface FormFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  placeholder: string;
  containerClassName?: string;
}

/**
 * input form component combined with bootstreap css classes for ui
 */
export default function FormField({ name, onChange, type, className = '', containerClassName = '', placeholder, label, }: FormFieldProps) {
  return (
    <div className={`form-group ${containerClassName}`}>
      <input
        onChange={onChange}
        type={type}
        className={`form-control font-weight-normal h-auto ${className}`}
        id={name}
        name={name}
        placeholder={placeholder}
      />
    </div>
  )
}

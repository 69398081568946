import React from "react";

import "./style.css";

export default function GradientButton({ children, className, type }: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
  return (
    <button
      type={type}
      className={`btn border-0 text-white text-decoration-none text-uppercase btn__gradient py-2 ${className}`}>
      {children}
    </button>
  );
}
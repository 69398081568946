import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "../components/image";

import ContactIcon from "../components/svg/contact";
import FormField from "../components/formInput";
import GradientButton from "../components/gradient-button";
import { contactApiLink } from '../assets/data/links.json';
import '../assets/css/nos-contacts.css';

const NosContactsPage: React.FC<PageProps<any>> = ({ data }) => {
  const { pageImage, site, } = data;
  const { title, path, description } = site?.siteMetadata?.pages?.nosContacts;
  const [contactData, setContactData] = useState({
    loading: false,
    message: '',
  });

  const onSubmit = async (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();
    if (typeof window !== 'undefined') {
      setContactData({
        ...contactData,
        loading: true
      });
      const $form = document?.querySelector('form');
      const formData = new FormData($form || undefined);
      try {
        const reqPromise = await fetch(
          contactApiLink,
          {
            body: formData,
            method: 'POST',
          }
        );
        const response = await reqPromise.json();
        if (response.code === 'failed') {
          setContactData({
            ...contactData,
            message: response.message,
            loading: false
          });
        } else {
          setContactData({
            ...contactData,
            message: response.message,
            loading: false
          });
        }

        window?.scrollTo(0, 200)
      } catch (error) {
        setContactData({
          ...contactData,
          message: 'Ressayez svp',
          loading: false
        })
      }
    }
  }

  return (
    <Layout className='home__page relative overflow-hidden' headerClassName='navbar-wrapper'>
      <SEO title={title} description={description} path={path} image={pageImage?.childImageSharp?.fluid?.src} />
      <section className='main__block position-relative'>
        <Image
          src={pageImage?.childImageSharp?.fluid}
          alt="nos contacts"
          className='image__full-width hero__bg position-absolute opacity_5 w-100 h-100' />
        <div className="container page__container text-white position-relative z-index_1">
          <div className="container__row row">
            <h1 className="main-title col font-weight-normal text-uppercase">
              <ContactIcon className='title__icon mr-3' />
              CONTACT
            </h1>
          </div>
          <div className="container__row row">
            <div className="col-sm-12 col-md-6 mt-2 mt-md-4 mb-3">
              <p className="pt-0 pt-md-2">
                <strong>Alg-one.com</strong> est édité par l’agence <a href="https://www.dropzik.com" className='title mt-0 mb-4 link' target="_blank" rel="noreferrer noopener">Dropzik Numérique Inc</a> qui a pour objectif de <strong>réunir une communauté de DJs</strong> et <strong>animateurs</strong> partout dans <strong>le monde</strong>!
                Au travers de ca <strong>web radio</strong> diffuser dans les 4 coins du globe, Elle se positionne comme une <strong>vitrine de l'Algérie à l’international</strong>.
                Ainsi, depuis sa création en <time dateTime="2009-12">décembre 2009</time>, ce sont plus de <strong>50 deejays</strong> qui ont eu l’occasion de <strong>mixer sur ses antennes</strong>.
              </p>
            </div>
            <div className="col-sm-12 col-md-6 bg_dark-light">
              <form className="" onSubmit={onSubmit}>
                {
                  contactData.message && (
                    <div className="alert alert-info rounded-0 btn__gradient text-white">{contactData.message}</div>
                  )
                }
                <FormField placeholder='Votre nom complet (requis)' name='fullname' />
                <FormField placeholder='Votre Email (requis)' type='email' name='email' />
                <FormField placeholder='Sujet' name='subject' />
                <div className="form-group">
                  <textarea rows={8} name='message' className='form-control' placeholder='Votre Message'></textarea>
                </div>
                <div className="form-group d-flex justify-content-end">
                  <GradientButton type='submit'>
                    envoyer
                  </GradientButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query NosContactsQuery {
    site {
      siteMetadata {
        lang
        contacts {
          email
          phone
        }
        pages {
          nosContacts {
            title
            path
            description
          }
        }
      }
    }
    pageImage: file(relativePath: { eq: "nos-contacts/bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default NosContactsPage;
